"use client"

import Button from "components/forms/Button";
//import useCallTracking from 'thirdparty/CallTracking/useCallTracking';


function PhoneCallButton() {
   // const callRailPh = useCallTracking();


    return(
        <>
            <Button 
                type="button" 
                variant="link"
                color="transparent" 
                noAnimate
                onClick={() => window.open(`tel:${process.env.phone}`, "_self")}
            >
                {process.env.phone}
            </Button>
        </>
    )
}

export default PhoneCallButton;