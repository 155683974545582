/**
 * Constants file for JSON and Strings
 * NOT to be used for configurations that will never change
 *  - if you want to store configurations add it to an .env.* or next.config
 */


export const metaRobots = {
    index: true,
    follow: true,
    nocache: true,
    googleBot: {
        index: true,
        follow: true,
        noimageindex: false,
        'max-video-preview': -1,
        'max-image-preview': 'large',
        'max-snippet': -1,
    },
}


export const searchMetaRobots = {
    index: false,
    follow: false
}


export const mobileCookieOptions = {
    path: "/",
    secure: process.env.NODE_ENV !== "development", 
    maxAge: 60*60*24*365,
    domain: process.env.NODE_ENV === "development" ? null : ".authenteak.com" 
}



export const SS_API = {
    END_POINTS: {
        SEARCH: '/products',
        SUGGEST: '/suggest/query',
        AUTO: '/autocomplete',
    },
    SEARCH_DEFAULT_PARAMS: {
        siteId: process.env.NEXT_PUBLIC_SS_siteId,
        ajaxCatalog: 'v3',
        resultsFormat: 'native',
        withFacets: 'true',
        withSortingOptions: 'true',
        resultsPerPage: 48
    },
    SUGGEST_DEFAULT_PARAMS: {
        siteId: process.env.NEXT_PUBLIC_SS_siteId
    },
    AUTO_DEFAULT_PARAMS: {
        resultsPerPage: 48,
        querySuggestions: 'true',
    }
}

export const SearchApiConstants = {
    END_POINTS: {
        SEARCH: '/products',
        CONTENT_FEED: '/content-feed',
        AUTO: '/autocomplete',
        PRODUCTS_VIEWED: '/track/products-viewed',
        PRODUCTS_CLICKED: '/track/products-clicked',
        PRODUCTS_CONVERTED: '/track/products-converted',
    },
    SEARCH_DEFAULT_PARAMS: {
        withFacets: 'true',
        withSortingOptions: 'true',
        resultsPerPage: 48
    },
    AUTO_DEFAULT_PARAMS: {
        resultsPerPage: 4,
        querySuggestions: 'true',
        highlightResults: 'content_feed',
    }
}



// Google Map showroom Location
import logo from 'public/assets/logos/OG00008_032621_PinterestProfilePhoto.jpg'
import { isMobile } from 'react-device-detect'

export const showroomLocationMap = {
    center: { 
        lat: 33.78878354689432, 
        lng: -84.42430503191379 
    },
    zoom: 16,
    infoWindowContent: `
        <div id="content" style="padding:1rem; display:flex; align-items:center">
            <img src="${logo.src}" alt="at logo" width="75" height="75" />

            <div style="padding-left:1rem">
                <h2 id="firstHeading">
                    AuthenTEAK Atlanta Showroom
                </h2>
                <div id="bodyContent">
                    <p style="margin:0">
                        Retail showroom for a selection of outdoor<br/>
                        kitchens, grills & fire pits, seating, planters & more.
                    </p>
                </div>
            </div>
        </div>`
}



export const blurImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPctHaZMQAGWAI64a/W5QAAAABJRU5ErkJggg==";




// Global Toast default Options
export const toastDefaults = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true
}



/*  
    Fabric Options Display Names ~ 
    - primary use: to determine swatch ordering 
    - rather look in to flag from PIM instead of this
*/

export const popOverOptions = [
    "Select Head Cushion Fabric",
    "Select Canopy",
    "Select Optional Cushions",
    "Select Optional Cushion",
    "Select Fabric",
    "Select Color",
    "Select Fabric Color",
    "Select Frame",
    "Select Cushion",
    "Select Sling",
    "Select Throw Pillows Fabric"
];


/**
 * These can be swatch ordered
 */

export const fabricOptions = [
    "Select Head Cushion Fabric",
    "Select Canopy",
    "Select Optional Cushions",
    "Select Optional Cushion",
    "Select Fabric",
    "Select Cushion",
    "Select Throw Pillows Fabric",
    "Select Sling", 
    "Select Sling Fabric", 
    "Select Base Cushion", 
    "Select Back Cushion"
];




// display names that are brand fabric table related
export const displayNamesForFabricTable = [
    "select cushion", 
    "select optional cushion", 
    "select fabric", 
    "select color",
    "select canopy"
];




// React Query Config Options
export const QueryClientDefaultOptions = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 3600000,
            cacheTime: 1000 * 60 * 60 * 12, // 12 hours
        }
    }
};


// Big Commerce Product Requests
export const includeOptions = "include=line_items.physical_items.options"


// Fireglass Calculator
export const fireglassOptions = {
    materials: [
        {   
            id: "fireGlass",
            img: "https://authenteak.s3.us-east-2.amazonaws.com/fireglass-calculator/Fireglass-image.jpg",
            name: "Fireglass"
        },
        {   
            id: "lavaRocks",
            img: "https://authenteak.s3.us-east-2.amazonaws.com/fireglass-calculator/Lava-rocks-image.jpg",
            name: "Lava Rock"
        }
    ],
    features: [
        {
            id: "fireBowl",
            img: "https://authenteak.s3.us-east-2.amazonaws.com/Category_assets/firebowl_img.png",
            name: "Fire Bowl",
            shape: "ellipsoid",
            note: "",
            dimensions: [
                {
                    label: "Measurement A - Diameter",
                    name: "diameter"
                },
                {
                    label: "Measurement B - Desired depth of",
                    name: "depth", 
                },
            ]
        },
        {
            id: "firePlace",
            img: "https://authenteak.s3.us-east-2.amazonaws.com/Category_assets/fireplace_img.png",
            name: "Fire Place",
            shape: "prism",
            note: 'We recommend 2" - 4" of glass depth for most fireplaces',
            dimensions: [
                {
                    label: "Measurement A - Fireplace Front Width",
                    name: "width"
                },
                {
                    label: "Measurement B - Fireplace Back Width",
                    name: "backWidth"
                },
                
                {
                    label: "Measurement C - Length",
                    name: "length"
                },
                {
                    label: "Measurement D - Desired depth of",
                    name: "depth", 
                }
            ]
        },
        {
            id: "rectFirePit",
            img: "https://authenteak.s3.us-east-2.amazonaws.com/Category_assets/rectangularfire_img.png",
            name: "Rectangular Firepit",
            shape: "cube",
            note: "For both square and rectangular shapes",
            dimensions: [
                {
                    label: "Measurement A - Width",
                    name: "width"
                },
                {
                    label: "Measurement B - Length",
                    name: "length"
                },
                {
                    label: "Measurement C - Desired depth of",
                    name: "depth", 
                },
            ]
        },
        {
            id: "roundFirePit",
            img: "https://authenteak.s3.us-east-2.amazonaws.com/Category_assets/roundfire_img.png",
            name: "Round Firepit",
            shape: "cylinder",
            note: "",
            dimensions: [
                {
                    label: "Measurement A - Diameter",
                    name: "diameter"
                },
                {
                    label: "Measurement B - Desired depth of",
                    name: "depth", 
                },
            ]
        }
    ]
};

//Color Disclaimer for PDP
export const colorDisclaimerTxt = "Colors may vary based on differences in monitors, lighting, dye lots, and fibers. If you're shopping for fabrics, order free swatches to most accurately view colors."


// My Account Dropdown
export const MyAccountFlyout = [
    {
        name: "Orders",
        title: "View order status",
        url: "/account/order-status"
    },
    {
        name: "Profile",
        title: "Update your account",
        url: "/account/profile"
    },
    {
        name: "Address Book",
        title: "For a faster checkout",
        url: "/account/address-book"
    },
    {
        name: "Recently Viewed",
        title: "Your product viewing history",
        url: "/account/recently-viewed"
    }
];




// Main Navigation Hard Coded
export const mainNavItems = [
    {
        name: "Outdoor Furniture",
        seoName: "All Outdoor Furniture",
        id: "flyoutOutdoorFurniture",
        type: "outdoor-furniture",
        href: "/outdoor-furniture",
        height: "short"
    },
    {
        name: "Umbrellas",
        seoName: "Patio Umbrellas and Accessories",
        id: "flyoutUmbrellas",
        type: "patio-umbrellas-accessories",
        href: "/patio-umbrellas-accessories",
        height: "short"
    },
    {
        name: "Heating",
        seoName: "All Outdoor Heating",
        id: "flyoutHeating",
        type: "outdoor-heating",
        href: "/outdoor-heating",
        height: "wee"
    },
    {
        name: "Outdoor Decor",
        seoName: "Outdoor Decor",
        id: "flyoutOutdoorDecor",
        type: "outdoor-decor",
        href: "/outdoor-decor",
        height: "wee"
    },
    {
        name: "Planters",
        seoName: "All Planters",
        id: "flyoutPlanters",
        type: "planters",
        href: "/planters",
        height: "wee"
    },
    {
        name: "Grills & Kitchens",
        seoName: "Grills and Outdoor Kitchens",
        id: "flyoutGrillsKitchens",
        type: "grills-outdoor-kitchens",
        href: "/grills-outdoor-kitchens",
        height: "short"
    },
    {
        name: "Accessories",
        seoName: "All Patio Accessories",
        id: "flyoutAccessories",
        type: "accessories",
        href: "/accessories",
        height: "wee"
    },
    {
        name: "Brands",
        seoName: "All Brands",
        id: "flyoutBrands",
        type: "shop-all-brands",
        href: "/shop-all-brands",
        height: "default"
    }
];



export const aboutFooterNav = {
    name:  "about",
    title: "About",
    items: [
        {
            href: "/c/store-locator",
            text: "Atlanta Showroom",
            title: "Find us"
        },
        {
            href: "/c/about-us",
            text: "Our Story",
            title: "About Us"
        },
        {
            href: "/c/careers",
            text: "Careers",
            title: "Work for us"
        },
        {
            href: "/c/newsletter",
            text: "Newsletter",
            title: "Latest Information in your inbox"
        },
        {
            href: "/c/authenteak-gives-back",
            text: "AuthenTEAK Gives Back",
            title: "How we give"
        },
        {
            href: "/c/sustainable-outdoor-furniture-accessories/",
            text: "Sustainability",
            title: "Sustainability"
        },
        {
            href: "/c/contact-us",
            text: "Report A Bug",
            title: "Found an issue?"
        }
    ]
}





export const resourcesFooterNav = {
    name:  "resources",
    title: "Resources",
    items: [
        {
            href: "/p/catalogs",
            text: "Catalogs",
            title: "Product Listings"
        },
        {
            href: "/c/furniture-care",
            text: "Furniture Care",
            title: "Maintenance"
        },
        {
            href: "/c/warranties",
            text: "Warranties",
            title: "Have an issue?"
        },
        {
            href: "/blog",
            text: "Blog",
            title: "Blog Articles"
        },
        {
            href: "/c/privacy-policy",
            text: "Privacy Policy",
            title: "Legal"
        },
        {
            href: "/c/sitemap",
            text: "Site Map",
            title: "All pages"
        },
        {
            href: "/c/trade",
            text: "Trade Professionals",
            title: "Learn about our trade program"
        },
        {
            href: "/c/authenteak-outdoor-furniture-protection-by-extend",
            text: "Product Protection",
            title: "Learn about AuthenTEAK's furniture protection by Extend "
        }
    ]
}



export const customerServiceFooterNav ={
    name:  "service",
    title: "Customer Service",
    items: [
        {
            href: "/account",
            text: "My Account",
            title: "Order History & More"
        },
        {
            href: "/account/order-status",
            text: "Track My Order",
            title: "Orders"
        },
        {
            href: "/help",
            text: "Help Center",
            title: "Order Help and FAQs"
        },
        {
            href: "/c/affirm",
            text: "Affirm Financing",
            title: "Pre-Qualify Today"
        },
        {
            href: "/c/policies",
            text: "Return Policy",
            title: "Returns"
        },
        {
            href: "/c/shipping",
            text: "Shipping",
            title: "Delivery Information"
        },
        {
            href: "/c/design-services",
            text: "Free Design Services",
            title: "Trade Designers"
        }
    ]
}




// US State List for Select Box Component
export const states = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]