"use client"

import PropTypes from 'prop-types';
import { useEffect, useState, useContext, Suspense, lazy } from "react";
import dynamic from 'next/dynamic';
import clsx from "clsx";
import useMeasure from 'react-use/lib/useMeasure';
import { useIntersect } from "custom_hooks/useIntersect";
import AuxillaryLinks from "components/applications/header_footer/Header/components/AuxillaryLinks";
import HeaderLogo from "components/applications/header_footer/Header/components/HeaderLogo";
import MenuBtn from "components/applications/header_footer/Header/components/MenuBtn";
import HeaderSearch from 'components/search/HeaderSearch';
import { useRouter } from 'next/navigation';
import { HeaderDropdownContext } from "components/applications/header_footer/Header/contexts/HeaderContext";
import { GlobalContext } from 'contexts/GlobalContext';
import useImmobility from './hooks/useImmobility';
import { HeaderContext } from 'components/applications/header_footer/Header/contexts/HeaderContext';
import BannerLoader from 'components/ads/BannerLoader';
import MyAccountBtn from  'components/applications/header_footer/Header/components/MyAccountBtn'
import Chat from 'components/help/Chat'
import MyCartBtn from 'components/applications/header_footer/Header/components/MyCartBtn'


const Ad = dynamic(() => import('components/applications/header_footer/Header/components/Flyout/Ad'))
const CountdownPencilBanner = dynamic(() => import('components/ads/CountdownPencilBanner'))
const Utility = dynamic(() => import('components/applications/header_footer/Header/components/Flyout/Utility'))
const CloseBtn = dynamic(() => import('components/forms/CloseButton'))
const MoreBtn = dynamic(() => import('components/applications/header_footer/Header/components/MoreBtn'))
const MainNav = dynamic(() => import('components/applications/header_footer/Header/components/MainNav'))
const Backdrop = dynamic(() => import('components/feedback/Backdrop'))


//const PencilBanner = lazy(() => import('components/ads/PencilBanner'))
const TradePencilBanner = lazy(() => import('components/ads/TradePencilBanner'))


function MobileHeader({ 
    isLandscape, 
    isPortrait,
    Style,
    AuxStyle,
    LogoStyle,
    HeaderSearchStyle,
    ChatStyle,
    MyCartStyle,
    PencilBannerStyle,
    UtilityStyle,
    MainNavStyle,
    MenuBtnStyle
}){
    const router = useRouter()
    const headerCtx = useContext(HeaderContext);
    const [ global, _ ] = useContext(GlobalContext);
    const [ dropDownShown, setDropDownShown ] = useState(false);


    // isImmobile as in the header is not scrolling and is in a non-moving position 
    const [ isImmobile, setImmobility ] = useState(true);
    const [ isOpen, openMobileFlyout ] = useState(false);


    const toggleFlyout = () => {
        openMobileFlyout(!isOpen)
    }


    const [ headerRef, entry ] = useIntersect({
        threshold: 1.0,
        rootMargin: "200px"
    });

    const [ headerWrapper, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    

    const canImmobilize = useImmobility({ entry, router })
    
    useEffect(() => {
        setImmobility(canImmobilize);

        return () => {
            setImmobility(false);
        }

    }, [ canImmobilize ])


    
    useEffect(() => {
        openMobileFlyout(false)

    }, [ router.asPath ])



    return(   
        <Suspense>
            {isOpen && (
                <div className={Style.flyout}>
                    {global.user.customer_group_id !== 1 ? (
                        //<PencilBanner Style={PencilBannerStyle} />
                        <CountdownPencilBanner />
                    ):(
                        <TradePencilBanner />
                    )}

                    <CloseBtn 
                        shouldClose={toggleFlyout} 
                        className={Style.close} 
                    />

                    <Ad />

                    <MainNav 
                        Style={MainNavStyle}
                        flyout 
                        isMobile 
                    />
                    
                    <Utility />
                </div>
            )}
           

            <header className={Style.cntr} ref={headerRef}>
                <div className={Style.wrapper} ref={headerWrapper}>
                    <div className={Style.auxCntr}>
                        <AuxillaryLinks Style={AuxStyle} />
                    </div>

                    <div className={Style.bodyWrapper}>
                        <HeaderDropdownContext.Provider value={{ dropDownShown, setDropDownShown }}>
                            <div 
                                className={clsx(Style.bodyCntr, !isImmobile && Style.bodyCntr__fixed)} 
                            >

                                <div className={clsx(Style.body, isImmobile && Style.wrap)}>
                                    <div className={clsx(Style.top, !isImmobile && Style.topImmobile)}>
                                        <MenuBtn 
                                            Style={MenuBtnStyle}
                                            {...{ isImmobile, toggleFlyout }} 
                                        />
                                        
                                        <div 
                                            className={clsx(Style.bar, Style.mobileBar, (!isImmobile && isLandscape) && Style.barLandscape)} 
                                            style={{ order: isImmobile ? 2 : 3 }}
                                        >
                                            {isImmobile && (
                                                <HeaderLogo 
                                                    Style={LogoStyle}
                                                    headerIsFixed={isImmobile} 
                                                    {...{ user: {...global.user}, isPortrait, isLandscape }} 
                                                    isMobile
                                                />
                                            )}
                                            

                                            {isImmobile && (
                                                <>
                                                    {/* <Chat 
                                                        Style={ChatStyle}
                                                        type="icon" 
                                                        headerIsFixed={isImmobile} 
                                                        isLandscape={isLandscape} 
                                                    />  */}
                                                    
                                                    <MyAccountBtn 
                                                        Style={UtilityStyle}
                                                        hideFlyout={headerCtx.showUnderlay} 
                                                        {...{ user: {...global.user}, isPortrait, isLandscape }}
                                                        isHandheld
                                                    />

                                                    <MyCartBtn 
                                                        Style={MyCartStyle}
                                                        {...{ user: {...global.user}, router }} 
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <HeaderSearch 
                                        order={isImmobile ? 3 : 2}
                                        headerIsFixed={isImmobile} 
                                        isLandscape={isLandscape} 
                                        isMobile
                                        Style={HeaderSearchStyle}
                                    />


                                    {!isImmobile && (
                                        <div className={Style.immobileAux}>
                                            <MyCartBtn 
                                                isImmobile
                                                {...{ user: {...global.user}, router }} 
                                                Style={MyCartStyle}
                                            />
                                        
                                            <MoreBtn 
                                                hideFlyout={headerCtx.showUnderlay} 
                                                isPortrait={isPortrait} 
                                                isLandscape={isLandscape} 
                                                isMobile
                                            />
                                        </div>
                                    )}
                                </div>

                            </div>
                        </HeaderDropdownContext.Provider>
                    </div>
                </div>
            </header>


            <Suspense fallback={<BannerLoader />}>
                {global.user.customer_group_id !== 1 ? (
                    //<PencilBanner Style={PencilBannerStyle} />
                    <CountdownPencilBanner />
                ):(
                    <TradePencilBanner />
                )}
            </Suspense>


            {headerCtx.showUnderlay && (
                <Backdrop 
                    show={headerCtx.showUnderlay} 
                    isImmobile={isImmobile} 
                    headerHeight={height} 
                    updateOverlay={() => headerCtx.updateOverlay(!headerCtx.showUnderlay)} 
                />
            )}
        </Suspense>
    )
}



MobileHeader.propTypes = {
    isLandscape: PropTypes.bool,
    isPortrait: PropTypes.bool
};


export default MobileHeader;