"use client"

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useState } from 'react';
import { IconPlus } from "components/icons/IconPlus";
import Style from "./SimpleDrawer.module.scss"
import utils from 'styles/globals/utils.module.scss';
import Divider from 'components/display/Divider';
import Button from 'components/forms/Button';


function SimpleDrawer({
    height,
    title,
    name,
    children,
    isTitleLarge,
    isOpen = false,
    step,
    color,
    stepColor,
    dividerColor="ocean",
    className,
    meta,
    subTitle,
    showMore,
    containerClassName,
    dividerType,
    marginTop,
    marginBottom,
    backgroundColor,
    icon = null
}) {
    const [toggle, setToggle] = useState(isOpen)
    const Icon = icon;


    return (
        <div className={clsx(Style.block, dividerType === 'expand' && Style.blockExpand)}>
            <details
                className={clsx(Style.toggleControl, {[Style.drawerContainer]: dividerType === 'expand'}, stepColor && utils[`bg_${stepColor?.class ?? color}`], className)}
                onClick={() => setToggle(!toggle)}
                name={name}
            >
                <summary className={clsx(Style.summary, color && utils[`bg_${color?.class ?? color}`])}>
                    {dividerType === "expand" ? (
                        <div className={Style.buttonAndDivider}>
                            <Divider
                                color={dividerColor}
                                className={clsx( 
                                    className,
                                    marginTop && utils[`mt_md_${marginTop}`], 
                                    marginBottom && utils[`mb_md_${marginBottom}`],
                                    [utils.mt_2, utils.mb_2],
                                )}
                            />

                            {!toggle && (
                                <Button 
                                    color={backgroundColor || "ocean"}
                                    size="lg"
                                    className={Style.learnMoreButton}
                                >
                                    {title ? title : "Learn more"}
                                </Button>
                            )}
                        </div>
                    ):(
                        <p className={clsx(isTitleLarge && utils.fs_4, Style.titleCntr)}>
                            {step && (
                                <span className={clsx(Style.step, stepColor && utils[`bg_${stepColor}`])}>
                                    {step}
                                </span>
                            )}


                            <span className={Style.titleBlock}>
                                <span className={Style.title}>
                                    {title}

                                    {icon && (
                                        <>{Icon}</>
                                    )}

                                    {meta && (
                                        <span className={Style.meta}>
                                            &mdash; {meta}
                                        </span>
                                    )}
                                </span>

                                {subTitle && (
                                    <small className={Style.subTitle}>
                                        {subTitle}
                                    </small>
                                )}
                            </span>
                        </p>
                    )}

                    <IconPlus 
                        width={25}
                        height={25} 
                        className={clsx(Style.toggleIcon, toggle && Style.iconRotate)} 
                    />
                </summary>
            
                {children}
            </details>

            <div className={Style.buttonAndDivider}>
                {toggle && dividerType === 'expand' && (
                    <Button 
                        color={backgroundColor || "ocean"}
                        size="sm"
                        type="button"
                        className={Style.closeButton}
                        onClick={() => setToggle(!toggle)}
                    >
                        X
                    </Button>
                )}
            </div>
        </div>
    )
}


SimpleDrawer.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    title: PropTypes.string,
    isTitleLarge: PropTypes.bool,
    isOpen: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    subTitle: PropTypes.string,
    meta: PropTypes.string,
    step: PropTypes.number,
    stepColor: PropTypes.string,
    showMore: PropTypes.bool,
    containerClassName: PropTypes.string,
    backgroundColor: PropTypes.object
};


export default SimpleDrawer;