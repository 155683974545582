import { useEffect, useState } from "react";
import { setCookie } from 'nookies'
import { mobileCookieOptions } from "lib/Constants";
import { storageKeys } from "services/LocalStorage.service";
import { isTouchScreenDevice } from "lib/Utils/isHandHeldDevice";


/**
 * Used to check in app.js if the current device is mobile from the start
 * so that we don't load what is not needed or shown on mobile
 * 
 * @param {boolean} isMobile 
 * @param {boolean} hasMobileCookie
 * @returns 
 */

export default function useMobileOverride(isMobile, hasMobileCookie){
	const [ mobileOverride, setMObileOverride ] = useState(isMobile)

    useEffect(() => {
		if( !hasMobileCookie ){
			const isTouchDevice = isTouchScreenDevice();

			// double check that this is actually a touch device, if not we set it as desktop, no touch
			// sometimes get a false positive on the mobile
			if( isMobile && !isTouchDevice ){
				setMObileOverride(false)
				setCookie(null, storageKeys.isMobile, false, mobileCookieOptions);
	
			}else{
				setCookie(null, storageKeys.isMobile, isTouchDevice, mobileCookieOptions);
				setMObileOverride(isTouchDevice)
			}
		}

	}, [
		isMobile,
		hasMobileCookie
	])


    return mobileOverride;
}