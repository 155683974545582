

export function isTabletDevice(){
    return window.matchMedia('(min-width:767px) and (max-width: 1050px)')
}


export function isMobileDevice(){
    return window.matchMedia('(max-width:767px)')
}


export function isTouchScreenDevice(){
    return (window.matchMedia && window.matchMedia('(any-pointer: coarse)').matches)
}